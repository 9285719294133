<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="申请人" prop="applicantId">
                <a-select
                  show-search
                  placeholder="请输入申请人姓名查找"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleUserSearch"
                  @change="handleUserChange"
                  :loading="loading"
                  v-model="queryParam.applicantId"
                >
                  <a-select-option v-for="d in userList" :key="d.userId">
                    {{ d.userName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="费用类型" prop="expenseType">
                <a-select placeholder="请选择费用类型" v-model="queryParam.expenseType" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in expenseTypeOptions" :key="index" :value="d.dictValue">{{
                    d.dictLabel
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="申请日期起止">
                  <a-range-picker
                    style="width: 100%"
                    @change="handleApplyDateChange"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="审批状态">
                  <a-select
                    placeholder="请选择审批状态"
                    v-model="queryParam.approvalStatus"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option
                      v-for="(d, index) in approvalStatusTypeOptions"
                      :key="index"
                      :value="d.dictValue">{{ d.dictLabel }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:expenses:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tenant:iot:expenses:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:iot:expenses:remove']">
          <a-icon type="delete" />删除
        </a-button> -->
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:expenses:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :isAmortizeOptions="isAmortizeOptions"
        :expenseTypeOptions="expenseTypeOptions"
        :paymentTypeOptions="paymentTypeOptions"
        :projectOptions="projectOptions"
        :unitOptions="unitOptions"
        @ok="getList"
      />
      <!-- <expense-detail ref="expenseDetail" :expenseTypeOptions="expenseTypeOptions" @ok="getList" /> -->
      <!-- 审批详情 -->
      <approval-detail ref="approvalDetail" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <template slot="title">
          <span class="total-amount-label">金额汇总：</span><span class="total-amount">{{ totalAmount }}</span>
        </template>
        <span slot="applyTime" slot-scope="text, record" :title="parseTime(record.applyTime)">
          {{ parseTime(record.applyTime) }}
        </span>
        <span slot="isAmortize" slot-scope="text, record">
          {{ isAmortizeFormat(record) }}
        </span>
        <span slot="expenseType" slot-scope="text, record" :title="setExpenseType(record)">
          {{ setExpenseType(record) }}
        </span>
        <span slot="approvalStatus" slot-scope="text, record" :title="approvalStatusFormat(record)">
          {{ approvalStatusFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:expenses:edit']" v-if="record.approvalStatus == '4'" />
          <a
            @click="handlePay(record, undefined)"
            v-hasPermi="['tenant:iot:expenses:edit']"
            v-if="record.approvalStatus == '4'"
          >
            <a-icon type="edit" />付款
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:expenses:edit']" />
          <a @click="handleView(record)" v-hasPermi="['tenant:iot:expenses:edit']">
            <a-icon type="info-circle" />查看
          </a>
          <a
            v-if="record.approvalStatus !== '0' && record.approvalStatus !== '1'"
            @click="toPrint(record)"
            v-hasPermi="['tenant:iot:expenses:edit']"
          >
            <a-icon type="info-circle" />打印
          </a>
          <button style="display: none" ref="printBtn" v-print="printObj">x</button>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <div style="display: none">
        <pExpenses @readyPrint="readyPrint" :expenseId="expenseId"></pExpenses>
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import debounce from 'lodash/debounce'
import { listExpenses, delExpenses, exportExpenses, updateExpenses, getProjects } from '@/api/iot/expenses'
import CreateForm from './modules/CreateForm'
// import ExpenseDetail from './modules/ExpenseDetail.vue'
import approvalDetail from '@/views/approval/approvalDetail'
import { listUser } from '@/api/system/user'
import pExpenses from '@/views/printouts/expenses.vue'

export default {
  name: 'Expenses',
  components: {
    CreateForm,
    approvalDetail,
    pExpenses
  },
  data() {
    this.handleUserSearch = debounce(this.handleUserSearch, 500)
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 是否可摊销字典
      isAmortizeOptions: [],
      // 查询参数
      queryParam: {
        applicantId: null,
        expenseType: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '所属项目',
          dataIndex: 'deptName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请人',
          dataIndex: 'applicantName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '费用类型',
          dataIndex: 'expenseType',
          scopedSlots: { customRender: 'expenseType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请时间',
          dataIndex: 'applyTime',
          scopedSlots: { customRender: 'applyTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请金额',
          dataIndex: 'applyAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '月费用加总',
          dataIndex: 'expenseTotal',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否可摊销',
          dataIndex: 'isAmortize',
          scopedSlots: { customRender: 'isAmortize' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审批状态',
          dataIndex: 'approvalStatus',
          scopedSlots: { customRender: 'approvalStatus' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      paymentTypeOptions: [
        {
          dictLabel: '财务直接对外付款',
          dictValue: '1'
        },
        {
          dictLabel: '员工自己付款后报销',
          dictValue: '2'
        },
        {
          dictLabel: '备用金冲抵',
          dictValue: '3'
        }
      ],
      approvalStatusTypeOptions: [],
      totalAmount: 0,
      projectOptions: [],
      unitOptions: [],
      printObj: {
        id: 'expensesPrint',
        popTitle: '费用申请',
        // extraCss:
        //   'https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback(vue) {
          vue.printLoading = true
          console.log('打开之前')
        },
        openCallback(vue) {
          vue.printLoading = false
          console.log('执行了打印')
        },
        closeCallback(vue) {
          console.log('关闭了打印工具')
        }
      },
      expenseId: '',
      reMounted: true
    }
  },
  filters: {},
  created() {
    this.getData()
  },
  computed: {},
  watch: {},
  methods: {
    async getData() {
      // 由于列表需要字典数据，所以要先获取到字典数据，再获取列表
      await this.getDictData()
      this.getList()
    },
    async getDictData() {
      this.loading = true
      await this.getDicts('sys_yes_no').then((response) => {
        this.isAmortizeOptions = response.data
      })
      await this.getDicts('iot_expense_type').then((response) => {
        this.expenseTypeOptions = response.data
      })
      await this.getDicts('iot_approval_status_type').then((response) => {
        this.approvalStatusTypeOptions = response.data
      })
      await getProjects({}).then((response) => {
        this.projectOptions = response.data
      })
      await this.getDicts('iot_account_unit').then((response) => {
        this.unitOptions = response.data.map((p) => p.dictValue)
      })
      this.loading = false
    },
    /** 查询费用记录列表 */
    async getList() {
      this.loading = true
      await listExpenses(this.queryParam).then((response) => {
        this.list = response.rows
        let applyAmount = 0
        this.list.forEach((p) => {
          applyAmount += Number(p.applyAmount)
        })
        this.totalAmount = applyAmount.toFixed(2)
        this.total = response.total
        this.loading = false
      })
    },
    // 是否可摊销字典翻译
    isAmortizeFormat(row, column) {
      return this.selectDictLabel(this.isAmortizeOptions, row.isAmortize)
    },
    approvalStatusFormat(row, column) {
      return this.selectDictLabel(this.approvalStatusTypeOptions, row.approvalStatus)
    },
    setExpenseType(row, column) {
      return this.selectDictLabel(this.expenseTypeOptions, row.expenseType)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        applicantId: undefined,
        expenseType: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delExpenses(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportExpenses(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handlePay(row) {
      const that = this
      row.approvalStatus = 5
      this.$confirm({
        title: '确认支付所选中数据?',
        content: '',
        onOk() {
          return updateExpenses(row).then((response) => {
            that.$message.success('付款成功', 3)
            that.getList()
          })
        },
        onCancel() {}
      })
    },
    handleUserSearch(value) {
      console.log('handleUserSearch', value)
      const queryParam = {
        userName: value
      }
      this.loading = true
      listUser(queryParam).then((response) => {
        this.userList = response.rows
        this.loading = false
      })
    },
    handleUserChange(value) {
      console.log('handleUserChange', value)
    },
    handleApplyDateChange(data, dateString) {
      this.queryParam.params = {
        startTime: dateString[0] + ' 00:00:00',
        endTime: dateString[1] + ' 23:59:59'
      }
    },
    toPrint(record) {
      console.log('toPrint', record)
      this.expenseId = ''
      this.$nextTick(() => {
        this.expenseId = record.id
      })
    },
    readyPrint() {
      this.$refs.printBtn.click()
    },
    handleView(record) {
      console.log('record', record)
      const approvalRecord = {
        // 发起人
        promoter: record.applicantName,
        // 审批模块
        approvalKind: 'expenses',
        // 费用记录ID
        approvalKindId: record.id,
        // 审批状态
        approvalStatus: record.approvalStatus,
        // 审批流程Key
        processInstanceId: record.instanceId
      }
      this.$refs.approvalDetail.handleDetail(approvalRecord)
    }
  }
}
</script>
<style scoped>
.total-amount-label {
  font-weight: 800;
  font-size: 18px;
}
.total-amount {
  font-weight: 800;
  font-size: 18px;
}
</style>
